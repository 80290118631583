import axios, { InternalAxiosRequestConfig, AxiosInstance } from 'axios';
import User from 'components/data/User';
import EditorData from 'components/editor-data/EditorData';

/**
 * Set initial request data
 */
const BricksRequest: AxiosInstance = axios.create({
    baseURL: process.env.APP_V2_API_HOST,
    timeout: Number(process.env.APP_API_TIMOUT),
    withCredentials: true
});

/**
 * Intercept requests
 * 1.   Add auth information as default to request, will be overridden if is set
 *      in the request data.
 */
BricksRequest.interceptors.request.use(
    (config: InternalAxiosRequestConfig<unknown>): InternalAxiosRequestConfig<unknown> => {
        try {
            const JWTtoken: string = (() => {
                return User.get('apiToken');
            })();

            if (JWTtoken) config.headers.Authorization = `Bearer ${JWTtoken}`;

            const campaignId = EditorData.getId();
            if (campaignId) config.params = { ...config.params, campaignId };

            return config;
        } catch (e) {
            return config;
        }
    },

    (error) => {
        return Promise.reject(error);
    }
);

export { BricksRequest };
export default BricksRequest;
