import { FileType } from '../types/brick.type';

export const SAVE_INTERVAL = 3000;

// Models
export const MODEL_TEMPLATE = 'data.template';
export const MODEL_SELECTED_PLATFORMS = 'data.selectedPlatforms';
export const MODEL_ASSETS = 'data.assets';
export const MODEL_ASSET = 'data.asset';
export const MODEL_CREATIVES = 'data.creatives';
export const MODEL_ASSET_VALIDATION_RESULTS = 'results.asset';
export const MODEL_PUBLISH_METADATA = 'data.publish.data'; // TODO Remove this one if it's removed from cm360
export const MODEL_PRESETS = 'data.presets';
export const MODEL_UPLOAD_RESTRICTION_TYPE = 'data.uploadRestrictionType';

export const MODEL_SETTINGS = 'data.settings';
export const MODEL_SETTINGS_FORMATS = 'data.settings.formats';

export const MODEL_DATASET_DETAILS = 'data.settings.dataset';
export const MODEL_AD_SETUP = 'data.adSetup';

// Brick Models
export const MODEL_ROLES_ASSIGNEES = 'data.briefing.roles.assignees';
export const MODEL_ROLES_REVIEWERS = 'data.briefing.roles.reviewers';
export const MODEL_DEADLINE = 'metadata.dateDeadline';
export const MODEL_START_DATE = 'metadata.dateStart';
export const MODEL_END_DATE = 'metadata.dateEnd';
export const MODEL_METADATA = 'metadata';
export const MODEL_STATUS = 'metadata.status';
export const MODEL_TITLE = 'title';
export const MODEL_BRICKS = 'bricks';
export const MODEL_TEMP_BRICK = 'temporaryBrick'; // This temporary brick is used for brick requests
export const MODEL_CHILDREN = 'children';
export const MODEL_ASSET_UPLOAD_IS_LOADING = 'assetUpload.isLoading';
export const MODEL_BRIEFING_DESCRIPTION = 'data.briefing.description';
export const MODEL_BRIEFING_ATTACHMENTS = 'data.briefing.attachments';
export const MODEL_UPDATED_BRICKS = 'updatedBricks';
export const MODEL_UPDATED_AT = 'updatedAt';
export const MODEL_PUBLISH_DIALOG = 'publishDialog';
export const MODEL_PUBLISH_DIALOG_PREVIEW_ID = 'publishDialog.previewItemId';

export const ACCEPTED_FILE_TYPES: FileType[] = ['image', 'video', 'audio', 'zip'];
export const BRICK_ID_PREFIX = 'i_';

export const PAGE_LIMIT = 10;
// Constants used for validators within bricks
export const AD_SETUP = 'brick.data?.adSetup?'; // Ad setup data from the brick object
export const PARENT_SETTINGS_PLACEMENT_TARGETING = `parent?.data?.settings?.targeting?.placements?`; // Targeting placements from the parent settings
