import Resources from 'components/data/Resources/components';
import { Brick, BrickSubType } from 'components/bricks/types/brick.type';
import User from 'components/data/User';
import { Workflow } from '../workflow.type';
import { fallbackWorkflowData } from './fallback-workflow';

/**
 * WorkflowManager is a class for managing workflows in a centralized manner.
 * It provides methods for adding, retrieving, and accessing workflows by their identifier.
 * This approach centralizes workflow data and facilitates CRUD operations, ensuring consistency across the application.
 */
class WorkflowManager {
    private static workflows: Record<string, Workflow> = {};

    /**
     * Retrieve a workflow by its identifier.
     * @param identifier The unique identifier of the workflow.
     * @returns The workflow corresponding to the given identifier, or undefined if not found.
     */
    public static getWorkflowByIdentifier(identifier: string): Workflow | undefined {
        return WorkflowManager.workflows[identifier];
    }

    /**
     * Add a new workflow to the WorkflowManager.
     * @param identifier The unique identifier of the workflow.
     * @param workflow The workflow data to be added.
     * If a workflow with the same identifier already exists, it will not be added.
     */
    public static addWorkflow(identifier: string, workflow: Workflow): void {
        if (!identifier) return;
        // Check if the workflow already exists
        if (!WorkflowManager.workflows[identifier]) {
            // If it's not a duplicate, add the workflow
            WorkflowManager.workflows[identifier] = workflow;
        }
    }

    /**
     * Load workflows from resources and store them in the WorkflowManager.
     * This method fetches workflows from the Resources and populates the workflows collection.
     */
    public static loadWorkflowsFromResources(): void {
        const fetchedWorkflows = Resources.get('bricks_workflows') as { workflows: Record<string, Workflow> } | null;

        if (fetchedWorkflows && fetchedWorkflows.workflows) {
            Object.entries(fetchedWorkflows.workflows).forEach(([identifier, workflow]) => {
                WorkflowManager.addWorkflow(identifier, workflow);
            });
        } else {
            const fallbackWorkflow = fallbackWorkflowData.workflows.fallbackWorkflow;
            WorkflowManager.addWorkflow('fallbackWorkflow', fallbackWorkflow);
        }
    }

    /**
     * Get the workflow for a specific brick subtype.
     * @param brickSubtype - The brick subtype to get the workflow for.
     * @returns The applicable workflow, or undefined if none is found.
     */
    public static getWorkflowForSubtype(brickSubtype: BrickSubType): Workflow | undefined {
        const workflowsArray = Object.values(WorkflowManager.getAllWorkflows());
        // Find a specific workflow for the given subtype
        const specificWorkflow = workflowsArray.find((workflow: Workflow) => workflow.subTypes.includes(brickSubtype));

        // Return the specific workflow if found, otherwise return the default workflow
        return specificWorkflow || workflowsArray.find((workflow) => workflow.subTypes.includes('all'));
    }

    /**
     * Check if a workflow with the given identifier already exists in the WorkflowManager.
     * @param identifier The identifier of the workflow to check.
     * @returns True if a workflow with the given identifier exists, otherwise false.
     */
    public static workflowExists(identifier: string): boolean {
        return WorkflowManager.workflows[identifier] !== undefined;
    }

    /**
     * Retrieve all stored workflows.
     * @returns An array of all workflows stored in the WorkflowManager.
     */
    public static getAllWorkflows(): Record<string, Workflow> {
        return WorkflowManager.workflows;
    }

    /**
     * Check if the user has the general required role to make changes to the workflow.
     * @param brick The brick to check.
     * @returns True if the user has the required role to edit the workflow, otherwise true if no roles are specified.
     */
    public static canEditWorkflow(brick: Brick): boolean {
        if (!brick) return false;

        const workflow = WorkflowManager.getWorkflowForSubtype(brick.subType);
        if (!workflow) return false;

        // If hasUserRole is not defined, allow everyone to access
        if (!workflow.hasUserRole || !Array.isArray(workflow.hasUserRole) || workflow.hasUserRole.length === 0) {
            return true;
        }

        // Check if the user has at least one of the required roles
        return workflow.hasUserRole.some((role) => User.hasType(role));
    }

    /**
     * Retrieve all statuses namings for all workflows.
     * @returns statuses names for all workflows.
     */
    public static getWorkflowsStatusesMapping(): Record<string, string> {
        const workflows = this.getAllWorkflows();

        const mapping: Record<string, string> = {};

        Object.values(workflows).forEach((workflow) => {
            workflow.statuses.forEach((status) => {
                mapping[status.id] = status.status;
            });
        });

        return mapping;
    }
}

export { WorkflowManager };
