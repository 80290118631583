/**
 * This map is used to display the correct prefix for the filter chips.
 */
export const filterChipsPrefixMap = {
    assignee: 'Assignee:',
    reviewer: 'Reviewer:',
    deadline: 'Deadline:',
    startDate: 'Start date:',
    endDate: 'End date:',
    status: 'Workflow status:'
};

export const defaultFilterSetup = [
    {
        type: 'selectMultiple',
        name: 'subType',
        label: 'Type',
        path: 'subType'
    },
    {
        type: 'selectMultiple',
        name: 'status',
        label: 'Workflow status',
        path: 'metadata.status'
    },
    {
        type: 'userMultiple',
        name: 'assignee',
        label: 'Assignee',
        path: 'data.briefing.roles.assignees'
    },
    {
        type: 'userMultiple',
        name: 'reviewer',
        label: 'Reviewer',
        path: 'data.briefing.roles.reviewers'
    },

    {
        type: 'datePicker',
        name: 'deadline',
        label: 'Deadline'
    },
    {
        type: 'datePicker',
        name: 'startDate',
        label: 'Start date'
    },
    {
        type: 'datePicker',
        name: 'endDate',
        label: 'End date'
    }
];
