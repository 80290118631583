import { Brick, BrickSubType } from 'components/bricks/types/brick.type';

/**
 * Defines the structure of a workflow, which is a series of statuses and transitions
 * representing a process for managing bricks.
 */
export interface Workflow {
    /**
     * Unique identifier for the workflow.
     */
    id: string;

    /**
     * The name of the workflow.
     */
    name: string;

    /**
     * Array of brick subtypes (e.g., "meta_campaign", "group") that this workflow applies to.
     */
    subTypes: BrickSubType[];

    /**
     * Array of statuses representing the stages or steps in this workflow.
     */
    statuses: WorkflowStatus[];

    /**
     * Array of transitions defining the connections and rules between statuses in the workflow.
     */
    transitions: WorkflowStatusTransition[];

    /**
     * Array of user roles required to perform ANY action on the workflows.
     * If not specified, the workflows are open to all users and extra requirements can be specified on the transition.
     */
    hasUserRole?: UserType[];
}

/**
 * Represents an individual status (stage) in a workflow.
 */
export interface WorkflowStatus {
    /**
     * Unique identifier for the status.
     */
    id: string;

    /**
     * The name or label displayed for this workflow status (e.g., "In Progress").
     */
    status: string;

    /**
     * Additional information for the workflow status.
     */
    message: string;

    /**
     * Indicates whether this status is the starting point of the workflow.
     */
    isStartStatus?: boolean;

    /**
     * Indicates whether this status is the ending point of the workflow.
     */
    isEndStatus?: boolean;

    /**
     * A color associated with the status, used for display (e.g., for badges or labels).
     * Can be one of the predefined values ('default', 'error', 'info', 'success') or a custom color string.
     */
    color: 'default' | 'error' | 'info' | 'success' | string;

    /**
     * Array of status IDs that this status is allowed to transition to.
     */
    allowedToStatuses?: string[];
}

/**
 * Represents a single validation rule for a workflow transition.
 */
export interface TransitionValidation {
    /**
     * Message explaining why this validation failed.
     */
    message: string;

    /**
     * Condition (as a string) that must be met for the validation to pass.
     */
    condition: string;
    /**
     * Tab to open after clicking 'solve'
     */
    solveTab?: string;
}

/**
 * Defines a transition between two statuses in a workflow.
 */
export interface WorkflowStatusTransition {
    /**
     * Unique identifier for the transition. Format: "e{source}-{target}".
     */
    id: string;

    /**
     * The ID of the source status where the transition starts.
     */
    source: string;

    /**
     * The ID(s) of the target status where the transition ends.
     */
    target: string[];

    /**
     * Array of predefined validations (e.g., "hasReviewer", "hasAssignee").
     * These are common validation checks applied to many workflows.
     */
    predefinedValidations?: PredefinedValidationType[];

    /**
     * Array of custom validations, allowing more specific rules for this transition.
     */
    customValidations?: TransitionValidation[];

    /**
     * Array of user roles required to perform this transition.
     * If not specified, the transition is open to all users.
     */
    hasUserRole?: UserType[];

    /**
     * Notifications triggered when the transition occurs.
     * These can include priority, type, and user types who should be notified.
     */
    notifications?: {
        /**
         * Priority of the notification (e.g., low, medium, high).
         */
        priority: number;

        /**
         * The type of notification (e.g., email, in-app alert).
         */
        type: string;

        /**
         * Array of user types (roles) to whom the notification should be sent.
         */
        hasUserType?: UserType[];
    };

    /**
     * Indicates whether this transition requires user confirmation.
     */
    shouldConfirm?: boolean;

    /**
     * The message displayed to the user when confirmation is required for this transition.
     */
    confirmMessage?: string;
}

/**
 * Types of predefined validations that can be applied to a transition.
 */
export type PredefinedValidationType =
    | 'hasAssignee'
    | 'hasReviewer'
    | 'hasDeadline'
    | 'isAfterDeadline'
    | 'hasBriefingDescription'
    | 'hasBriefingAttachments'
    | 'hasDateRange'
    | 'isInDateRange'
    | 'hasCreatives'
    | 'hasCorrectMedia'
    | 'areAllChildrenInTargetStatus';

/**
 * Types of user roles that can be associated with notifications or validations.
 */
export type UserType =
    | 'user'
    | 'designer'
    | 'campaignManagerDepartment'
    | 'campaignManagerBrand'
    | 'campaignManager'
    | 'manager'
    | 'administrator'
    | 'superadmin';

export interface ConfirmationDetails {
    /**
     * Whether confirmation is required for the status change.
     */
    isRequired: boolean;

    /**
     * Message displayed to the user for confirmation.
     */
    message: string;
}

export enum ValidationType {
    VALIDATION = 'validation',
    ROLE = 'role'
}

/**
 * Represents the result of a validation process.
 */
export interface WorkflowValidationResult {
    /**
     * Type of the validation result, distinguishing between role and general validation errors.
     */
    type: ValidationType;

    /**
     * Array of validation error messages.
     */
    messages: string[];

    /**
     * Tab to open after clicking 'solve'
     */
    solveTab?: string;
}

export interface ValidationData {
    /**
     * Validation messages associated with the status change.
     */
    failedValidations: Record<string, WorkflowValidationResult>;

    /**
     * Indicates if multiple bricks are selected for the status change.
     */
    hasMultipleBricksSelected: boolean;
}

/**
 * Represents the state of a status change operation within a workflow.
 */
export interface StatusChangeState {
    validationData: ValidationData;

    /**
     * The target status ID to which the bricks are being moved.
     */
    targetStatus: string;

    /**
     * The status currently being in process of confirming.
     */
    pendingStatus: string;

    /**
     * Details about user confirmation requirements for the status change.
     */
    confirmationDetails: ConfirmationDetails;

    /**
     * Indicates if there are mismatched workflows among the selected bricks.
     */
    hasMismatchedWorkflows: boolean;
}

/**
 * Stores validation results for a specific brick in a workflow.
 */
export interface ProcessedValidationData {
    /**
     * The brick being validated
     */
    brick?: Brick;

    /**
     * Validation result with messages and type
     */
    validationResult: WorkflowValidationResult;
}

/**
 * Initial state for the status change operation.
 */
export const initialStateStatusChange: StatusChangeState = {
    validationData: {
        failedValidations: {},
        hasMultipleBricksSelected: false
    },
    targetStatus: '',
    pendingStatus: '',
    confirmationDetails: {
        isRequired: false,
        message: ''
    },
    hasMismatchedWorkflows: false
};
